import React from 'react';
import { useLocation, useRouteMatch, Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { ADMIN_URLS } from '../_config';

//sidebar images
import sidebar1 from '../../images/shared/sidebar-1.png';
import sidebar2 from '../../images/shared/sidebar-2.png';
import sidebar3 from '../../images/shared/sidebar-3.png';
import sidebar4 from '../../images/shared/sidebar-4.png';
import sidebar5 from '../../images/shared/sidebar-5.png';

import { getIsStaff } from '../Users';

import {SchedulingIcon, MessagingIcon, AdminIcon, NotepadIcon, ReportIcon } from './SideBarParts';

export const SideBarPage = () => {
  const location = useLocation();
  const matchIndex = useRouteMatch({path: "/", exact: true})
  const matchAdmin = useRouteMatch("/bd/admin")
  const match = useRouteMatch("/:page_name(photoassessments|appointments|requests|orders|prescriptions)")
  const isStaff = useSelector(getIsStaff);
  
  if (location){
    return (
      <nav className="Sidebar">
        <Link to="/">
          <button 
            onClick={null/*() => history.push("/")*/} 
            className={cn("Sidebar__link ", {"Sidebar__link--active": (!matchAdmin && matchIndex) || match || location.pathname.startsWith("/patient/")} )}
          >
              <NotepadIcon className={cn("Sidebar__icon--notepad ", {"Sidebar__icon--active": (!matchAdmin && matchIndex) || match || location.pathname.startsWith("/patient/")} )}/>
          </button>
        </Link>
        <Link to="/patients">
          <button 
            className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/patients") )} )} 
          >
            <AdminIcon className={cn("Sidebar__icon", {"Sidebar__icon--active": (location.pathname.startsWith("/patients"))  } )}/>
          </button>
        </Link>
        {/*<Link to="/billing">
          <button className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/billing") )} )} >
            <NotepadIcon className={cn("Sidebar__icon-notepad ", {"Sidebar__icon-notepad--active": (location.pathname.startsWith("/billing"))  } )}/>
          </button>
        </Link>
        <Link to="/billing">
          <button className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/reports") )} )} >
            <ReportIcon className={cn("Sidebar__icon ", {"Sidebar__icon--active": (location.pathname == "/") || (location.pathname.startsWith("/reports"))  } )}/>
          </button>
        </Link>*/}
        <Link to="/scheduled">
          <button 
            className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/scheduled") )} )} 
          >
            <MessagingIcon className={cn("Sidebar__icon", {"Sidebar__icon--active": (location.pathname.startsWith("/scheduled"))  } )}/>
          </button>
        </Link>
        { isStaff &&
          <Link to={ADMIN_URLS.home}>
            <button onClick={null} className={cn("Sidebar__link ", {"Sidebar__link--active": !!matchAdmin} )} >
              <ReportIcon className={cn("Sidebar__icon ", {"Sidebar__icon--active": !!matchAdmin} )} />
            </button>
          </Link>
        }
      </nav>
    )
  } else {
    return (<div></div>)
  }
}