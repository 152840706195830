import { Box, Flex, Image, Heading, Text, Avatar  } from '@chakra-ui/react'; 

import { startCase } from 'lodash';
import Logo from '../../../images/icons/logo';
import { getFullNameString } from 'js/_helpers';

import { useAuth } from 'js/_libs/auth';


export const Topbar = ({...props}) => {
  const { logout, user: {user} } = useAuth();

  console.log(user)

  const displayName = getFullNameString('{givenName} {familyName}', user.first_name, user.last_name)

  return (
      <Flex w={'full'} justify={'space-between'} pl={[12, 12]} py={[8, 8]} pr={[24,24]} {...props}>
        <Flex align={'center'}>
          <Logo />
        </Flex>
        <Flex>
          <Flex>
            <Avatar   
              borderRadius="full"
              boxSize={47}
              border={'3px solid #FFFFFF'}
              filter={'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))'}
              src={user?.avatar}
              name={displayName}
              bg={'brandLight.primary'}
              color={'white'}
              letterSpacing={'1.1px'}
              mr={6}
            />
          </Flex>
          <Flex direction={'column'} justify={'center'}>
            <Heading fontSize={14} fontWeight={500}>
              {displayName}
            </Heading>
            <Text fontSize={12} color={'darkGray'}>
              {startCase((user?.user_role || '').toLowerCase())}
            </Text>
          </Flex>
        </Flex>
      </Flex>
  )
}