import moment from 'moment';
import dayjs from 'dayjs';
import "dayjs/locale/ja";

import localizedFormat from "dayjs/plugin/localizedFormat";
import localeJa from "dayjs/locale/ja"; // With a custom alias for the locale object
import localeTh from "dayjs/locale/th"; // With a custom alias for the locale object

import {MOMENT_DATE_FORMAT, MOMENT_LONG_DATE_FORMAT} from '../_config';

dayjs.extend(localizedFormat);

/**
 * moment requires language with region or something did
 * what do i need to do for region
 */

export function getAppointmentTimeFormat(dateInput, format, lang){
  
  if (lang === 'th'){
    let date = dayjs(dateInput);
    if (format){
      // if messagetemplate has format prepared
      return date.locale(localeTh).format(format)
    } else {
      // otherwise default for langauge
      return `วันที่ ${date.locale(localeTh).format('D')} ${date.locale(localeTh).format('MMMM')} เวลา ${date.locale(localeTh).format('HH:mm')} น`;
    }
  } else if (lang === 'ja'){
    let date = dayjs(dateInput);
    if (format){
      // if messagetemplate has format prepared
      return date.locale(localeJa).format(format)
    } else {
      // otherwise default for langauge
      let hourmin = date.locale(localeTh).format('HH:mm');
      return `${date.locale(localeJa).format('lll (dd)')} ${hourmin.includes(':00') ? `${hourmin.slice(0,2)}時〜` : `${hourmin.slice(0,2)}時${hourmin.slice(-2)}分〜`}`
    }
  } else {
    // default for all
    let date = dayjs(dateInput);
    if (format){
      // if messagetemplate has format prepared
      return date.format(format)
    } else {
      // otherwise default for langauge
      return date.format('llll'); 
    }
  }
}

export function combineDateTime(dateInput, timeInput){

  // Parse the date and time values into a Day.js object
  var date = dayjs(dateInput?.value ? dateInput?.value : dateInput);
  var time = timeInput?.value ? timeInput?.value : timeInput;
  
  const [hours, minutes] = time.split(':');
  
  // Combine the date and time values into a single Day.js object
  var combinedDateTime = date.set('hour', hours).set('minute', minutes);
  return combinedDateTime.format("YYYY-MM-DDTHH:mm");
}

export function toHoursAndMinutes(d) {
  d = Number(d);
  var h = Math.floor(d / 60);
  var m = Math.floor(d % 60 / 60);
  var s = Math.floor(d % 60 % 60);

  var sign = (h > 0) ? "+" : "-";
  var signReverse = (h > 0) ? "-" : "+";


  var isoFormat = `${sign}${String(Math.abs(h)).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
  var isoFormatreverse = `${signReverse}${String(Math.abs(h)).padStart(2, '0')}:${String(m).padStart(2, '0')}`;
  return { h, m, s, sign, isoFormat, isoFormatreverse };
}

export function thaiDateFromUtc(time, locale){
  if (!time){
    return ""
  }
  return moment(time)
    .utcOffset(7 * 60)
    .locale(locale)
    .format(MOMENT_LONG_DATE_FORMAT);
}

export function offsetDateFromUtc(time, offset, locale, format=MOMENT_LONG_DATE_FORMAT){
  if (!time){
    return ""
  }
  return moment(time).locale(locale).format(format);
}

export function localeFormatDate(time, locale, format){
  var regionFormat = format || MOMENT_DATE_FORMAT;
  if (!time) {
    return '';
  }
  //const actualTime = moment(time).locale(locale).format(regionFormat);
  return moment(time).locale(locale).format(regionFormat);
}

export function localeFormatFromNow(time, locale) {
  if (!time) {
    return '';
  }

  //const actualTime = moment(time).locale(locale).fromNow();
  return moment(time).locale(locale).fromNow();
}

export function localeDatePlaceholder(locale) {
  return moment()
    .locale(locale)
    .localeData()
    .longDateFormat('L')
    .replace(/\W/g, '/');
}

export function greeting(datetime=null, currentHour=null) {
  if (currentHour == null){
    currentHour = moment(datetime).format('HH');
  }

  if (currentHour >= 3 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 15) {
    return 'Afternoon';
  } else if (currentHour >= 15 && currentHour < 20) {
    return 'Evening';
  } else if (currentHour >= 20 && currentHour < 3) {
    return 'Night';
  } else {
    return '';
  }
}

export function getTimeIndications(time) {
  var timeIndications = '';
  if (time >= 0 && time <= 11) {
    timeIndications = 'am';
  } else {
    timeIndications = 'pm';
  }

  return time + timeIndications;
}
