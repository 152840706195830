import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
console.log("this is the user stored: ", user)
const initialState = user ? { loggedIn: true, user } : {loggedIn:false, resetSuccess:false, };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.ENDUSER_RESETPASSWORD_REQUEST:
      return {
        loggingIn: true
      };
    case userConstants.ENDUSER_RESETPASSWORD_SUCCESS:
      return {
        loggedIn: true,
        resetSuccess: true
      };
    case userConstants.ENDUSER_RESETPASSWORD_FAILURE:
      return {};

    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      //localStorage.setItem('user', JSON.stringify(user));
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {loggedIn:false};
    default:
      return state
  }
}
