import React from 'react';
import PropTypes from 'prop-types';

class AdminIcon extends React.Component{
  render() {
    return (
    	<svg id='svg4770' viewBox='0 0 26 30' height='32' width='32' className="Sidebar__svg">
    <g id='layer1'>
        <g transform='translate(37.767 35.562)' id='web/profile_off' fill='#fff'  className={this.props.className} >
            <path id='path4589' d='m -23.71474,-30.435131 c -1.844379,0 -3.33975,1.495371 -3.33975,3.33975 0,0.98268 0.43654,1.892141 1.184829,2.530504 0.612095,0.521414 1.361864,0.810746 2.154921,0.810746 0.793296,0 1.543711,-0.289519 2.154591,-0.810465 0.748618,-0.638644 1.185159,-1.548105 1.185159,-2.530785 0,-1.844379 -1.495371,-3.33975 -3.33975,-3.33975 z'
            />
            <path id='path4591' d='m -18.682143,-19.758131 -10.065261,0 c -0.102669,0.102625 -0.153335,0.153251 -0.321292,0.321077 -0.233719,0.233625 -0.326071,0.32602 -0.456905,0.457187 -0.533442,0.533489 -0.533442,0.533489 -1.038399,1.038582 l -2.17e-4,2.17e-4 c -0.801179,0.801388 -1.260174,1.260397 -1.831398,1.831316 l 0,4.328621 17.36175,0 0,-4.328722 c -0.920101,-0.920101 -0.993456,-0.993456 -1.83116,-1.83116 l -1.038854,-1.038854 c -0.367718,-0.367717 -0.435455,-0.435455 -0.721157,-0.721157 l -0.05711,-0.05711 z'
            />
        </g>
    </g>
</svg>
    )
  }

}

class MessagingIcon2 extends React.Component{
  render(){
    return(
      <svg width="31" height="32" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={this.props.className}
      d="M14.5804 7.46042L2.07456 0.461587C1.86619 0.340074 1.62625 0.283627 1.38555 0.299497C1.14486 0.315367 0.914401 0.402829 0.723784 0.55065C0.529112 0.704244 0.38777 0.915258 0.319824 1.15374C0.251878 1.39221 0.260781 1.64603 0.345268 1.87917L2.43081 7.70534C2.45191 7.76343 2.4902 7.81372 2.54058 7.84951C2.59096 7.88531 2.65105 7.90492 2.71285 7.90573H8.07144C8.22551 7.90329 8.37478 7.95938 8.48911 8.06269C8.60344 8.166 8.67432 8.30884 8.68746 8.46237C8.69253 8.54341 8.68093 8.62462 8.65337 8.701C8.62581 8.77737 8.58288 8.84729 8.52724 8.90641C8.47159 8.96554 8.4044 9.01263 8.32983 9.04476C8.25527 9.07689 8.1749 9.09339 8.09371 9.09323H2.71285C2.65105 9.09404 2.59096 9.11365 2.54058 9.14944C2.4902 9.18524 2.45191 9.23553 2.43081 9.29362L0.345268 15.1198C0.282691 15.2993 0.263835 15.4911 0.290267 15.6793C0.316698 15.8676 0.387653 16.0468 0.497241 16.2021C0.606828 16.3574 0.751886 16.4843 0.920368 16.5723C1.08885 16.6603 1.27589 16.7069 1.46597 16.7081C1.66824 16.7072 1.86713 16.6562 2.04488 16.5596L14.5804 9.53854C14.7641 9.43425 14.9168 9.28314 15.023 9.10062C15.1293 8.9181 15.1853 8.71067 15.1853 8.49948C15.1853 8.28828 15.1293 8.08086 15.023 7.89833C14.9168 7.71581 14.7641 7.56471 14.5804 7.46042Z" 
      fill="#fff"/>
      </svg>
    )
  }
}

class SchedulingIcon extends React.Component{
  render() {
    return (
    <svg id='svg4770' viewBox='0 0 32 32' height='32' width='32' className="Sidebar__svg">
    	<g id='layer1'>
        	<g transform='translate(4.798 5.173)' id='web/calendar_on' fill='#fff' className={this.props.className} >
            	<rect height='15.4' width='16.4' y='4.8' x='3.8' id='rect4599' />
            	<path id='Path-3' d='m 3,10.466667 14.5,0' />
            	<path id='Path-4' d='m 7.7,2 0,5.2612305' />
            	<path id='Path-4-Copy' d='m 16.7,2 0,5.2612305' />
        	</g>
    	</g>
	</svg>
    )
  }

}

class MessagingIcon extends React.Component{
    render(){
        return (
            <svg width="32" height="32" viewBox="0 -8 16 32" className="Sidebar__svg">
                <g id="Symbols" stroke="none" fill="none" fillRule="evenodd" className={this.props.className}>
                    <g id="icn/chat/v1/on/off" transform="translate(-2.000000, -2.000000)">
                        <path d="M21.4553269,2.7106 L2.47779219,2.7106 L2.47779219,17.9600057 L7.48347011,17.9600057 L7.48347011,22.5498 L14.2067616,17.9600057 L21.4553269,17.9600057 L21.4553269,2.7106 Z M20.3811471,3.78776364 L20.3811471,16.8828421 L14.2067616,16.8828421 L13.8759142,16.8828421 L13.6019983,17.0691914 L8.55764993,20.5128835 L8.55764993,17.9600057 L8.55764993,16.8828421 L7.48347011,16.8828421 L3.551972,16.8828421 L3.551972,3.78776364 L20.3811471,3.78776364 Z" id="Fill-3" fill="#405159"></path>
                        <path strokeWidth="1" d="M8.07127978,10.275 L16.0491191,10.275" id="Stroke-4" stroke="#405159"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

class NotepadIcon extends React.Component{
    render(){
        return(
            <svg width="32" height="32" viewBox="0 -8 16 32" className="Sidebar__svg" >
                <g id="notepad" stroke="none" fill="none" fillRule="evenodd" className={this.props.className}>
                    <g id="Address_Page" transform="translate(-35.000000, -380.000000)" fill="#566973" fillRule="nonzero">
                        <g id="Group-28" transform="translate(0.000000, 72.000000)">
                            <g id="web_documents_off" transform="translate(35.000000, 308.000000)">
                                <polygon id="Fill-1" points="5 9.4 13 9.4 13 8 5 8"></polygon>
                                <polygon id="Fill-1-Copy" points="5 14.4 13 14.4 13 13 5 13"></polygon>
                                <polygon id="Fill-3" points="14.58 1.2 14.58 2.2 16.88 2.2 16.88 19.2 1.12 19.2 1.12 2.2 3.42 2.2 3.42 1.2 0 1.2 0 20.2 18 20.2 18 1.2"></polygon>
                                <path d="M3.42,0 L3.42,4.54 L14.58,4.54 L14.58,0 L3.42,0 Z M4.55,2.68 L4.55,1.3 L13.45,1.3 L13.45,3.3 L4.55,3.3 L4.55,2.68 Z" id="Fill-4"></path>
                            </g>
                        </g>
                    </g>
                </g>
    
            </svg>

        )
    }
}

class ReportIcon extends React.Component{
    render(){
        return(
            <svg width="32" height="32" viewBox="0 -8 16 32" className="Sidebar__svg">
                <g id="report" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" className={this.props.className}>
                    <g id="chart" transform="translate(-35.000000, -204.000000)" stroke="#566973" strokeWidth="1.2">
                        <g id="Group-28" transform="translate(0.000000, 72.000000)">
                            <g id="web_analysis_off" transform="translate(35.000000, 132.000000)">
                                <rect id="Rectangle-7" x="1.6" y="10.6" width="2.8" height="4.8"></rect>
                                <rect id="Rectangle-7-Copy" x="7.6" y="5.6" width="2.8" height="9.8"></rect>
                                <rect id="Rectangle-7-Copy-2" x="13.6" y="0.6" width="2.8" height="14.8"></rect>
                                <path d="M0,19 L18,19" id="Path-6"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export {SchedulingIcon, AdminIcon, MessagingIcon, NotepadIcon, ReportIcon};


