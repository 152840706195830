import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getIntlMessages } from '../Intl';

import { MdUnfoldMore as UnfoldMore } from 'react-icons/md';

import { range } from 'lodash';

import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'

import { Flex, Box, IconButton} from '@chakra-ui/react';

import { Form, Field } from 'react-final-form';
import { SelectAdapter } from '../_components';
import Select, {components} from 'react-select';

const baseCssClassName = "Pagination";
const pageCssClassName = baseCssClassName + "__currentPage";
const controlsCssClassName = baseCssClassName + "__controls";
const prevPageCssClassName = "prevPageButton";
const nextPageCssClassName = "nextPageButton";
const activeCssClassName = "active";

const pageNumberCssClassName = `${baseCssClassName}__PageNumber`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <UnfoldMore />
    </components.DropdownIndicator>
  )
}

class PaginationDropdown extends React.Component{

  pageClick = (inputValue) => {
    if (inputValue.value !== this.props.currentOffset){
      this.props.onPageNumberClick(inputValue.value);
    }
  }

  render(){
    const { totalPages, currentOffset } = this.props;
    const itemList = range(totalPages? totalPages : 1).map((pageNum) => {
      return {value: pageNum, label: pageNum+1}
    })
    return(
      <Box mx={3}>
        <Select
          className={pageNumberCssClassName}
          arrowRenderer={() => <UnfoldMore />}
          onChange={inputValue => this.pageClick(inputValue)}
          options={itemList}
          disabled={itemList.length < 2}
          isClearable={false}
          searchable={false}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          value={{value: currentOffset, label: currentOffset+1}}
          styles={{
            dropdownIndicator: (provided, state) => {
              return { ...provided, padding: 2, paddingLeft: 0 };
            },
            indicatorSeparator: (provided, state) => {
              const display = 'none';
              return { ...provided, display };
            },
            valueContainer: (provided, state) => {
              return { ...provided, minWidth: 30, paddingRight: 0, justifyContent:'center'}
            }
          }}
          components = {{DropdownIndicator}}
        />
      </Box>
    )
  }
};


class PaginationDropdown2 extends React.Component{
  constructor(props){
    super(props);
  }

  pageClick = (values) => {
    const { current_page } = values;
    this.props.onPageNumberClick(current_page);
  }

  render(){

    const { totalPages, currentOffset } = this.props;
    const itemList = range(totalPages).map((pageNum) => {
      return {value: pageNum, label: pageNum+1}
    })
    return(
      <Form
        onSubmit={this.pageClick}
        initialValues = {{current_page: currentOffset}}
        render = {({
          form,
          reset,
          submitting,
          validating,
          values
        }) => (
          <div className={''}>
            <Field
              className={''}
              name="current_page"
              items={itemList}
              component={SelectAdapter}
            />
          </div>
        )}
      />
    )
  }
};

export const Pagination = ({onPageClick, onPageNumberClick, currentOffset, totalPages}) => {
    const intlMessages = useSelector(getIntlMessages);
    const prevPageActive = (currentOffset === 0) ? false : true;
    const nextPageActive = (currentOffset >= totalPages-1) ? false : true;
    const totalPagesMin = Math.max(totalPages, 1)

    return (
      <Flex justify={["space-around", "flex-end"]} direction={["row", "row"]} h="full"  pt={[5, 0]} >
        <Flex align="center" justifyContent={["center", "flex-start"]} pr={[0, "35px"]} fontSize="16px" letterSpacing="0.5px">
            <PaginationDropdown
              currentOffset = {currentOffset}
              totalPages = {totalPages}
              onPageNumberClick = {onPageNumberClick}
            />
            <FormattedMessage
                id={'string.paginationOutOfTotal'}
                description= {'total pages'}
                defaultMessage={intlMessages['string.paginationOutOfTotal']}
                values={{
                  totalPages: totalPagesMin,
                  currentPage: currentOffset+1
                }}
            />
        </Flex>
        <Flex className={controlsCssClassName} justifyContent={["center", "flex-start"]} >
          <IconButton 
            className={cn(prevPageCssClassName, {[`${prevPageCssClassName}--${activeCssClassName}`]: prevPageActive})}
            isDisabled = {!prevPageActive}
            onClick = {() => onPageClick(-1)}
            mr={3}
            borderRadius="3px"
            border="1px solid #e6edf0"
            bg="#f7f9fa"
            icon={<FaAngleLeft fontSize={"18px"} 
              color={!prevPageActive ? "#999" : "gray.800"}  
              />
            } />
          <IconButton 
            className={cn(prevPageCssClassName, {[`${nextPageCssClassName}--${activeCssClassName}`]: nextPageActive})}
            isDisabled = {!nextPageActive}
            onClick = {() => onPageClick(1)}
            borderRadius="3px"
            border="1px solid #e6edf0"
            bg="#f7f9fa"
            icon={<FaAngleRight fontSize={"18px"} color={!nextPageActive ? "#999" : "#12171a" }    />} />
        </Flex>
      </Flex>
    )
}
