import { 
  Flex,
  FormControl, 
  FormLabel, 
  FormHelperText,
  Textarea, 
  FormErrorMessage 
} from '@chakra-ui/react';
import { AdminHelpPopover } from './AdminHelpPopover';
import { isFieldErrorfromMeta, getErrorMessage } from '../../_libs/forms';

export const SchedulingTextAreaField = ({input, meta, label, fontSize="14px", helpheader=null, helpbody= null, rows=6, isDisabled=false, placeholder=null, type='text'}) => {
  const labelIsInvisible = label === ' ';
  return (
    <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)}>
      <FormLabel
            letterSpacing={0} align="center" fontSize={fontSize} 
            pb={'8px'} visibility={labelIsInvisible ? 'hidden': 'visible'}
      >
        {label}
        {(helpbody || helpheader) && <AdminHelpPopover header={helpheader} body={helpbody} />}
      </FormLabel>
      <Textarea 
        {...input} 
        type={type} 
        minH="40px" 
        isDisabled={isDisabled}
        resize={'none'}
        rows={rows}
        fontSize="14px" 
        background="white"
        borderRadius={'10px'}
        borderColor={'#7B8887'}
        _placeholder={{
          color: '#7B8887'
        }}
        _disabled = {{
          cursor: 'default',
          borderColor: '#7B888780'
        }}
        placeholder={placeholder || label} 
      />
      <Flex height={'30px'} align={'center'}>
        {
          getErrorMessage(meta) 
            ? <FormErrorMessage >{getErrorMessage(meta)}</FormErrorMessage>
            : <FormHelperText></FormHelperText>
        }
      </Flex>
      
    </FormControl>
  )
}

export const TextAreaField = ({input, meta, label, helpheader=null, helpbody= null, labelColor='#12171a', rows=4, isDisabled=false, placeholder=null, type='text', inputProps={}}) => {
  return (
    <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)}>
      <FormLabel color={labelColor} pb={1} 
        fontSize="14px" >
        {label}
        {(helpbody || helpheader) && <AdminHelpPopover header={helpheader} body={helpbody} />}
      </FormLabel>
      <Textarea 
        {...input} 
        type={type} 
        minH="40px" 
        isDisabled={isDisabled}
        rows={rows}
        fontSize="14px" 
        background="white"
        placeholder={placeholder || label}
        {...inputProps}
      />
      {
        getErrorMessage(meta) 
          ? <FormErrorMessage>{getErrorMessage(meta)}</FormErrorMessage>
          : <FormHelperText></FormHelperText>
      }
      
    </FormControl>
  )
}