import { Redirect, useLocation } from 'react-router-dom';

import { useAuth } from '../_libs/auth';

export function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();

  console.log("require auth ", "checking user. ", location);

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return children;
}
