import { Grid, Box, Flex, Spinner } from '@chakra-ui/react';

import { Topbar } from '../Topbar';

export const MainLayout = ({ children }) => {
  console.log("mainlayout")
  return (
    <Grid 
      h="100vh" w="full" 
      position="relative" 
      templateAreas={`
        "topbar"
        "border"
        "main"`}
      gridTemplateRows={'max-content 1px auto'}
      gridTemplateColumns={'auto'}
    >
        <Topbar area={'topbar'} />
        <Box mx={4} borderBottom="1px" borderBottomColor={'#00948233'} area={'border'}/>
        <Flex h="full" w="full" direction={'column'} px={12} pt={8} area={'main'}>
          {children}
        </Flex>
    </Grid>
  );
};

export const MainLayoutSuspense = () => {
  return (
    <Box h="full" w="full" position="relative">
      <Flex h="full" w="full" direction={'column'} p={[0, 0]} >
        <Topbar />
        <Box mx={4} borderBottom="1px" borderBottomColor={'#33009482'}/>
        <Flex h="full" w="full" direction={'column'} px={12} pt={4}>
          <Spinner thickness="4px" speed="0.65s" size="xl" />
        </Flex>
      </Flex>
    </Box>
  );
}