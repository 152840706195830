export const required = value => ( value ? undefined : 'Required')
export const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const isFieldErrorfromMeta = (meta) =>
  (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit);
export const notEmpty = value => (Array.isArray(value) && value.length > 0 ? undefined : 'Required')
export const requiredMulti = (vals) => {
  if (vals == undefined){ return 'Required';}
  if (Array.isArray(vals) && vals.length < 1) {return 'Required';}
  return undefined;
}
export const mustBePositiveNumber = val => ((Number.isNaN(val) || val < 0) ? 'Must be positive' : undefined);
export const isNumberOrRange = (val) => (val || "").match(/^(\d+[-]\d+)|(\d+)$/) ? undefined : 'Must be number or range';
export function getErrorMessage(meta) {
  if (meta.error) {
    return meta.error;
  } else if (meta.submitError && Array.isArray(meta.submitError)) {
    return meta.submitError[0];
  }
  return '';
}
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)