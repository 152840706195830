import { 
  Flex,
  FormControl, 
  FormLabel, 
  FormHelperText,
  Input, 
  FormErrorMessage 
} from '@chakra-ui/react';
import { AdminHelpPopover } from './AdminHelpPopover';
import { isFieldErrorfromMeta, getErrorMessage } from '../../_libs/forms';


export const SchedulingInputField = ({input, meta, label, helpheader=null, isEmptyError=true, helpbody= null, onChange=null, fontSize="14px",isReadOnly=false, isDisabled=false, placeholder=null, type='text', ...props}) => {
  const labelIsInvisible = label === ' ';
  return (
    <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} {...props}>
      {
        (label || helpbody || helpheader) &&
          <FormLabel 
            textTransform={'capitalize'}
            letterSpacing={0} align="center" fontSize={fontSize} 
            pb={'0px'} visibility={labelIsInvisible ? 'hidden': 'visible'}
          >
            { labelIsInvisible ? 'default' : label}
            {(helpbody || helpheader) && <AdminHelpPopover header={helpheader} body={helpbody} />}
          </FormLabel>
      }
      <Input 
        {...input} 
        type={type} 
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        minH="40px" 
        fontSize={fontSize}
        background="white"
        borderRadius={'10px'}
        onChange={onChange ? onChange : input.onChange}
        borderColor={'#7B8887'}
        border = {'0.5px solid rgba(123, 136, 135, 0.5)'}
        _placeholder={{
          color: '#7B8887'
        }}
        _disabled = {{
          cursor: 'default',
          borderColor: '#7B888780'
        }}
        placeholder={placeholder || label}
        {...props.inputProps}
      />
        <Flex height={(isEmptyError || !getErrorMessage(meta) ) ? '10px' : '30px'} align={'center'}>
        {
          getErrorMessage(meta) 
            ? <FormErrorMessage >{getErrorMessage(meta)}</FormErrorMessage>
            : <FormHelperText></FormHelperText>
        }
      </Flex>
    </FormControl>
  )
}

export const InputField = ({input, meta, label, helpheader=null, helpbody= null, fontSize="14px", labelColor='#12171a', isDisabled=false, onChange=null, placeholder=null, type='text', ...props}) => {
  
  return (
    <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} {...props}>
      {
        (label || helpbody || helpheader) &&
          <FormLabel color={labelColor} align="center" fontSize={fontSize} pb={1}>
            {label}
            {(helpbody || helpheader) && <AdminHelpPopover header={helpheader} body={helpbody} />}
          </FormLabel>
      }
      <Input 
        {...input} 
        type={type} 
        isDisabled={isDisabled}
        minH="40px" 
        fontSize={fontSize}
        background="white"
        placeholder={placeholder || label} 
        onChange={onChange ? onChange : input.onChange}
        {...props.inputProps}
      />
      {
        getErrorMessage(meta) 
          ? <FormErrorMessage>{getErrorMessage(meta)}</FormErrorMessage>
          : <FormHelperText></FormHelperText>
      }
      
    </FormControl>
  )
}