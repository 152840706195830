import { useState } from 'react';
import {  useHistory, useLocation, useParams } from 'react-router-dom';
import { VStack, Flex, Box, FormControl, FormLabel, Text, InputGroup, Input, InputRightElement, FormHelperText,
  FormErrorMessage,  Button } from '@chakra-ui/react';
  import { useAuth } from '../_libs/auth';

import { InputField } from 'js/_components/Forms/InputField';
import { required, isFieldErrorfromMeta, getErrorMessage } from 'js/_libs/forms';

import { upperFirst } from 'lodash';
import { isBlank } from 'js/_helpers';
import { useCountdown  } from 'js/_hooks/useCountdown';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { FormattedMessage } from 'react-intl';
import { ButtonFilled } from 'js/_components/Button/ButtonFilled';
import PrevArrowIcon from 'images/icons/PrevArrowIcon';

const Countdown= ({time=null}) => {
  const timer = useCountdown(time);
  if (time == null){
    return (
      <FormattedMessage 
        id={'loginpage.authorizationCode.sendCode'}
        description={'loginpage authorization code send code button'}
        defaultMessage={'send code'}
      />
    );
  }
  if (timer[2] > 0){
    return (
      <FormattedMessage 
        id={'loginpage.authorizationCode.sendCode'}
        description={'loginpage authorization code send code button'}
        defaultMessage={'send code'}
      />
    );
  } else {
    return `${60 + timer[3]} s`;
  }
}

export const LoginEmailForm = ({onBack}) => {

  const urlParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const { login, isLoggingIn } = useAuth();

  const [ timeClicked, setTimeClicked ] = useState(null);

  const handleSendCode = (email=null) => {
    // send code
    if (isLoggingIn || isBlank(email)){
      setTimeClicked(null);
      return;
    }
    setTimeClicked(new Date((new Date()).getTime() + 60*1000));
  }

  const handleLogin = async (values) => {
    let from = location?.state?.from?.pathname;
    try{
      await login({...values, provider: ""});
      if (from && from !== '/login'){
        history.push(location.state.from.pathname)
      } else {
        history.push('/');
      }
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : 'general error'
      }
    }
  }
  
  return (
    <Form
      onSubmit={handleLogin}
      //mutators={{ setFieldData }}
      render = {({
        handleSubmit,
        reset,
        submitting,
        pristine,
        validating,
        values
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{width:'100%'}}
        >
        <Flex direction={'column'} justify={'space-between'}>
          <Box>
            <Flex justify={'flex-start'} align={'center'}>
              <PrevArrowIcon 
                onClick={onBack}
                _hover={{
                  cursor:'pointer'
                }}
                height={'15px'} width={'15px'} color={'black'} />
              <Text ml={10} color={'#1B2B29'} fontWeight={'600'} fontSize={'30px'}>
                {'Log In'}
              </Text>
            </Flex>
            <VStack spacing={10} mt={10}>
              
              <Field name="email" validate={required}>
                {({ input, meta }) => (
                  <InputField 
                    borderColor={'rgba(123, 136, 135, 0.5)'}
                    borderRadius={'15px'}
                    inputProps={{
                      borderRadius: '15px',
                      minH: '50px'
                    }}
                    input={input}
                    meta={meta}
                    label={upperFirst('email address')}
                  />
                )}
              </Field>

              <Field name={urlParams.login_type === 'admin' ? "password" : "auth_code"} validate={required}>
                {({ input, meta }) => (
                  
                  <FormControl id={input.name} isInvalid={isFieldErrorfromMeta(meta)} >
                  <FormLabel align="center" fontSize={"14px"} pb={1}>
                    {
                      (urlParams.login_type === 'admin') ?
                      (
                        <FormattedMessage 
                          id={'loginpage.password.label'}
                          description={'loginpage password label'}
                          defaultMessage={upperFirst('password')}
                        />)
                      : (
                        <FormattedMessage 
                          id={'loginpage.authorizationCode.label'}
                          description={'loginpage authorization code label'}
                          defaultMessage={upperFirst('authorization code')}
                        />)
                    }
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      {...input} 
                      borderColor={'rgba(123, 136, 135, 0.5)'}
                      borderRadius={'15px'}
                      pr="80px"
                      type={urlParams.login_type === 'admin' ? "password" : "text"}
                      minH="50px" 
                      fontSize={"14px"}
                      background="white"
                      placeholder={urlParams.login_type === 'admin' ? upperFirst('enter password') : upperFirst('enter verification code')} 
                    />
                    <InputRightElement width="80px" h={'50px'}>
                      <Flex h="50px"  align={'center'}>
                        <Button  
                          isDisabled={isBlank(values.email)}
                          letterSpacing={"1px"} size="sm" variant={"link"} color={"#179B8B"} 
                          onClick={() => handleSendCode(values.email)}>
                          <Countdown time={timeClicked} />
                        </Button>
                      </Flex>
                    </InputRightElement>
                  </InputGroup>
                  {
                    getErrorMessage(meta) 
                      ? <FormErrorMessage>{getErrorMessage(meta)}</FormErrorMessage>
                      : <FormHelperText></FormHelperText>
                  }
                  </FormControl>
                )}
              </Field>
            </VStack>
          </Box>
          <Flex mt={10}>
              <ButtonFilled
                bg={`brandLight`}
                w={'100%'}
                py={10}
                type={"submit"}
                isLoading={submitting}
              >
                {'Log In'}
              </ButtonFilled>
          </Flex>
          </Flex>
        </form>
      )} 
    />

  )
}