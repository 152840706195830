const storage = {
  getToken: () => {
    try{
      return JSON.parse(window.localStorage.getItem(`key`));
    } catch {
      return null;
    }
  },
  setToken: (token) => {
    window.localStorage.setItem(`key`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`key`);
  },
  setCountry: (country) => {
    window.localStorage.setItem(`country`, JSON.stringify(country));
  },
  getCountry: () => {
    try{
      return JSON.parse(window.localStorage.getItem(`country`));
    } catch {
      return null;
    }
    
  },
  setLocale: (locale) => {
    window.localStorage.setItem(`locale`, JSON.stringify(locale));
  },
  getLocale: () => {
    try{
      return JSON.parse(window.localStorage.getItem(`locale`));
    } catch {
      return null;
    }
    
  },
};

export default storage;
