import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS } from '../_config';

export const patientRequestsService = {
  getPatientDetails,
  getPatientRequests,
  getOngoingPatientRequests,
  getPatientRequestImages,
  getPatientImageSet,
  getPatientNotes,
  deletePatientUpload,
  changePatientImageSetStatus,
  createPatientNote,
  editPatientDetails
};

function editPatientDetails(patientId, data, dpid=null){
  const requestUrl = API_URLS.editPatientDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{patientId}', patientId);

  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientDetails(patientId, dpid=null){
  const requestUrl = API_URLS.getPatientDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId);

  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getOngoingPatientRequests(patientId, params={}, dpid=null){
  const requestUrl = API_URLS.getPatientRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId) + `?status=ongoing`;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientRequests(patientId, dpid=null, params={}){
  const requestUrl = API_URLS.getPatientRequests;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientImageSet(patientId, requestId=null, dpid=null){
  const requestUrl = API_URLS.getPatientImageSet;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId);
  if (requestId) {
    url = url + '?reqId=' + requestId
  }
  if (requestId && dpid){
    url = url + '&dpid='+ dpid;
  } else if (dpid) {
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientRequestImages(patientId, dpid=null){
  const requestUrl = API_URLS.getPatientRequestImages;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId);
  if (dpid) {
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getPatientNotes(patientId, dpid=null){
  const requestUrl = API_URLS.getPatientNotes;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patientId}', patientId);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function deletePatientUpload(patientId, data, dpid=null){
  const requestUrl = API_URLS.deletePatientUpload;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{patientId}', patientId);

  return fetch(url, requestOptions).then(handleResponse);
}

function changePatientImageSetStatus(data, dpid=null){
  const requestUrl = API_URLS.changePatientUploadStatus;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

function createPatientNote(patientId ,data, dpid=null){
  const requestUrl = API_URLS.createPatientNote;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{patientId}', patientId);

  return fetch(url, requestOptions).then(handleResponse);
}
