import React from 'react';
import {Router, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import 'babel-polyfill';
import '@fontsource/noto-sans-kr/900.css';
import '@fontsource/noto-sans-kr/700.css';
import '@fontsource/noto-sans-kr/500.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import {ChakraProvider, Center, Spinner, Box, Text} from '@chakra-ui/react';
import {history} from '../_helpers';

import {PrivateRoute} from '../_components';
import NavBar from '../_components/NavMenu/NavBar';
import {OverarchingDialog} from '../OverarchingDialog';
import {createSelector} from 'reselect';

import {GOOGLE_CLIENT_ID, AVAILABLE_LANGUAGES} from '../_config';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {AuthProvider} from '../_libs/auth';

import {lazyImport} from '../_helpers/lazyImport';
import {MainPageRoutes} from '../_routes/MainPageRoutes';
import {LoginPage} from '../LoginPage';
import {LoginAppPage} from '../LoginPage/LoginAppPage';

import {AdminPageRoutes} from '../AdminPage/routes';
import ScheduledMessagesRoutes from '../ScheduledMessages/routes';
import AppointmentsRoutes from '../Appointments/routes';
import TreatmentPlansRoutes from '../TreatmentPlans/routes';

import {TestPage} from '../TestPage';

import {HelmetProvider} from 'react-helmet-async';
import {QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {queryClient} from '../_libs/react-query';

import 'moment/locale/zh-cn';
import 'moment/locale/th';

import {settingsActions} from '../_actions';
import {settingsService} from '../_services';
import Notifications from 'react-notification-system-redux';
import {store} from '../_helpers/store';
import {theme} from '../../css/theme';

import {useLocale} from '../_hooks/useLocale';
import storage from '../_helpers/storage';

import 'react-datepicker/dist/react-datepicker.css';

import '../../css/bootstrap.min.css';
import '../../css/index.css';
import '../../css/home.style.css';
import '../../css/admin.style.css';
import { LineLinkAccount } from 'js/LineLinkAccount/LineLinkAccount';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  //...
};
const {NewConsultRequestPage} = lazyImport(
  () => import('../NewConsultRequestPage'),
  'NewConsultRequestPage'
);
const {PatientPage} = lazyImport(() => import('../PatientPage'), 'PatientPage');
const {PatientsList} = lazyImport(
  () => import('../Patients/PatientsList'),
  'PatientsList'
);
const {TreatmentplanPage} = lazyImport(
  () => import('../TreatmentplanPage/TreatmentplanPage'),
  'TreatmentplanPage'
);
const {PhotoAssessmentDetail} = lazyImport(
  () => import('../PhotoAssessments/PhotoAssessmentDetail'),
  'PhotoAssessmentDetail'
);
const {OrderDetail} = lazyImport(
  () => import('../Orders/OrderDetail'),
  'OrderDetail'
);
const {UserProfilePage} = lazyImport(
  () => import('../UserProfilePage'),
  'UserProfilePage'
);
const {PasswordForgotPage} = lazyImport(
  () => import('../PasswordForgotPage'),
  'PasswordForgotPage'
);
const {PasswordResetPage} = lazyImport(
  () => import('../PasswordResetPage'),
  'PasswordResetPage'
);

//const analytics = getAnalytics();
//logEvent(analytics, 'notification_received');

//const app = initializeApp(firebaseConfig);

const notifStyle = {
  NotificationIem: {
    DefaultStyle: {
      margin: '30px 50px',
      padding: '40px 20px',
    },
  },
};

const getNotificationsSlice = (state) => state.notifications;
const getNotifications = createSelector(
  [getNotificationsSlice],
  (notifications) => {
    return notifications;
  }
);

export const App = () => {
  const {setLocale} = useLocale();
  const initLang = React.useRef(null);
  const notifications = useSelector(getNotifications);

  React.useEffect(() => {
    if (initLang.current == null && setLocale != null) {
      const language =
        storage.getLocale() ||
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

      // Split locales with a region code
      //const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
      let currentLanguage = language || 'en-us';
      console.log('CURRENT LANG:', currentLanguage);
      if (
        AVAILABLE_LANGUAGES.map((lang, ind) => lang.value).indexOf(
          currentLanguage
        ) === -1
      ) {
        currentLanguage = 'en-us';
      }
      initLang.current = currentLanguage;
      setLocale(currentLanguage);
    }
  }, [setLocale, initLang]);

  return (
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <Router history={history}>
                <div
                  style={{fontFamily: "'Proxima Nova', Georgia, sans-serif"}}
                >
                  <Notifications
                    notifications={notifications}
                    style={notifStyle}
                    store={store}
                  />
                  <OverarchingDialog />
                  <React.Suspense
                    fallback={
                      <Box h="100vh">
                        <Box
                          py={9}
                          px={12}
                          w="100%"
                          background={'#253238'}
                          boxShadow={'2xl'}
                        >
                          <Text
                            fontSize={'18px'}
                            fontWeight={'bold'}
                            color={'#fff'}
                            letterSpacing={'2.4px'}
                          >
                            BEFOREDENT
                          </Text>
                        </Box>
                        <Center
                          h="calc(100vh - 72px)"
                          w="100vw"
                          backgroundColor={'transparent'}
                        >
                          <Spinner
                            size={'xl'}
                            thickness="5px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="primary.500"
                          />
                        </Center>
                      </Box>
                    }
                  >
                    <Route exact path="/test" component={TestPage} />
                    <Route path="/login/:login_type" component={LoginAppPage} />
                    <Route exact path="/login*" component={LoginAppPage} />
                    <Route
                      exact
                      path="/reset/:uid/:token"
                      component={PasswordResetPage}
                    />
                    <Route
                      exact
                      path="/forgot"
                      component={PasswordForgotPage}
                    />
                    <Route
                      exact
                      path="/linelink/:uid/:token"
                      component={LineLinkAccount}
                    />
                    <Route
                      exact
                      path="/linelink"
                      component={LoginAppPage}
                    />
                    <Route
                      exact
                      path="/treatment/:id"
                      component={TreatmentplanPage}
                    />
                    <PrivateRoute
                      exact
                      path="/requests/new"
                      component={NewConsultRequestPage}
                    />
                    <PrivateRoute
                      exact
                      path="/patient/:patient_id/:tab"
                      component={PatientPage}
                    />
                    <PrivateRoute
                      exact
                      path="/patient/:patient_id"
                      component={PatientPage}
                    />
                    <PrivateRoute
                      exact
                      path="/patients"
                      component={PatientsList}
                    />
                    <PrivateRoute
                      exact
                      path="/photoassessments/:request_id"
                      component={PhotoAssessmentDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/orders/:orderId"
                      component={OrderDetail}
                    />
                    <PrivateRoute
                      exact
                      path="/profile"
                      component={UserProfilePage}
                    />
                    <Route
                      path="/treatmentplans"
                      component={TreatmentPlansRoutes}
                    />
                    <Route
                      path="/appointments/"
                      component={AppointmentsRoutes}
                    />
                    <Route
                      path="/scheduled/"
                      component={ScheduledMessagesRoutes}
                    />
                    <Route path="/bd/admin/" component={AdminPageRoutes} />
                    <Route path="/" component={MainPageRoutes} />
                  </React.Suspense>
                </div>
              </Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
};
