import { confirmAlert } from 'react-confirm-alert';
import { store } from './store';
import { alertActions } from '../_actions';
import storage from '../_helpers/storage';

export function handleResponse(response){
    if (!response.ok){
        if (response.status == 401 ){
            //sign off
            storage.clearToken()
            window.location.reload();
        } else if (response.status == 403){
            // rediect user to
            // TODO: show a pop up maybe to say no permission and redirect user to home page?
            alert("you do not have permission to view this")
            /*confirmAlert({
              title: 'Creation Failed',
              message: 'There was an unexpected error on our end. Please try again.',
              buttons: [
                {
                  label: 'OK. I will',
                }
              ]
            })*/
        } else if (response.status === 500){
          store.dispatch(alertActions.show({type: 'general.error500'}))
        }else if (response.status === 404){
            //throw Error(response.statusText)
            return Promise.reject({"err": response.statusText}); 
        }else{
            //return Promise.reject(response.statusText);
            return response.json().then(Promise.reject.bind(Promise));
        }
    }
    if (response.status == 299){ // custom status code in order to
      return response.json().then(Promise.reject.bind(Promise));
    }
    return response.json();
};
