import { appointmentConstants, procedureConstants } from '../_constants';
import { appointmentService, procedureService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment';

export const appointmentActions = {
	unmountAppointment,
	setAppointmentID,
	selectTooth,
	selectProcedure,
    getAppointmentDetails,
    getVideoChatToken,
    addProcedure,
    gePatientUnassignedProcedures,
    getInbox,
    getMessages,
    getNewMessages,
    getProcedureCodes,
    getToothRegions,
    getNewInbox,
    getMessages,
    getNewMessages,
    sendMessage,
    openChatBox,
    closeChatBox,
    nextUploads,
    prevUploads,
    changeContentView,
    openVideoChat,
    closeVideoChat,
    openProcForm,
    closeProcForm,
    changeProcForm
};

function unmountAppointment(){
	return dispatch => {
		dispatch(unmount())
	}

	function unmount(){ return {type: appointmentConstants.UNMOUNT_APPT}}
}

function setAppointmentID(apptID){
	return dispatch => {
		dispatch(set(apptID))
	}

	function set(apptID){ return {type: appointmentConstants.SETAPPOINTMENT_ID, apptID}}
}

function openVideoChat(apptID){
	return dispatch => {
		dispatch(open(apptID))
	}

	function open(){ return {type: appointmentConstants.OPEN_VIDEO_CHAT, apptID}}
}

function closeVideoChat(){
	return dispatch => {
		dispatch(close())
	}

	function close(){ return {type: appointmentConstants.END_VIDEO_SESSION}}
}

function getVideoChatToken(sessionID){
	return dispatch => {
		dispatch(request());
		appointmentService.getVideoChatToken(sessionID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: appointmentConstants.GETVIDEOCHAT_TOKEN_REQUEST } }
    function success(data) {
    	return { type: appointmentConstants.GETVIDEOCHAT_TOKEN_SUCCESS, data }
    }
    function failure(error) { return { type: appointmentConstants.GETVIDEOCHAT_TOKEN_FAILURE, error } }
}

function changeContentView(contentView){
	return dispatch => {
		dispatch(change(contentView))
	}

	function change(contentView){ return {type: appointmentConstants.CHANGE_PAGECONTENT, contentView}}
}

function selectTooth(toothNum){
	return dispatch => {
		dispatch(set(toothNum))
	}

	function set(toothNum){ return {type: appointmentConstants.CHART_SELECT_TOOTH, toothNum}}
}

function selectProcedure(procID, toothNum){
	return dispatch => {
		dispatch(set(procID, toothNum))
	}

	function set(procID){ return {type: appointmentConstants.CHART_SELECT_TOOTH_PROCEDURE, action:{procID, toothNum}}}
}

function nextUploads(){
	return dispatch => {
		dispatch(next())
	}

	function next(toothNum){ return {type: appointmentConstants.NEXT_UPLOADS}}
}

function prevUploads(){
	return dispatch => {
		dispatch(prev())
	}

	function prev(){ return {type: appointmentConstants.PREV_UPLOADS}}
}


function getProcedureCodes(){
	return dispatch => {
		dispatch(request());
		appointmentService.getProcedureCodes()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: appointmentConstants.GETPROCEDURECODES_REQUEST } }
    function success(data) {
    	return { type: appointmentConstants.GETPROCEDURECODES_SUCCESS, data }
    };
    function failure(error) { return { type: appointmentConstants.GETPROCEDURECODES_FAILURE, error } }
};


function getToothRegions(){
	return dispatch => {
		dispatch(request());
		appointmentService.getToothRegions()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: appointmentConstants.GET_TOOTHREGIONS_REQUEST } }
    function success(data) {
    	return { type: appointmentConstants.GET_TOOTHREGIONS_SUCCESS, data }
    };
    function failure(error) { return { type: appointmentConstants.GET_TOOTHREGIONS_FAILURE, error } }
};

function getAppointmentDetails(appointmentID) {
	return dispatch => {
		dispatch(request());
		appointmentService.getAppointmentDetails(appointmentID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: appointmentConstants.GETAPPOINTMENT_DETAILS_REQUEST } }
    function success(data) {
    	let procedure_list = {};
    	const toothProceduresObj = data.appointment.procedures.reduce((accumulator, proc) => {
    		accumulator[proc.tooth] = (proc.tooth in accumulator) ? [...accumulator[proc.tooth], proc] : [proc] ;
    		return accumulator
    	}, {});
    	const toothProcedureList = data.appointment.procedures.map((proc, index) => {
    		return proc.tooth
    	});

    	data.appointment["toothObj"] = toothProceduresObj
    	data.appointment["toothList"] = toothProcedureList
    	return { type: appointmentConstants.GETAPPOINTMENT_DETAILS_SUCCESS, data }
    }
    function failure(error) { return { type: appointmentConstants.GETAPPOINTMENT_DETAILS_FAILURE, error } }
};

function getVideoChatDetails( dentistID, appointmentID){
	return dispatch => {
		dispatch(request());
		appointmentService.getVideoChatDetails(dentistID, appointmentID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: appointmentConstants.GETVIDEOCHAT_DETAILS_REQUEST } }
    function success(data) {
    	return { type: appointmentConstants.GETVIDEOCHAT_DETAILS_SUCCESS, data }
    }
    function failure(error) { return { type: appointmentConstants.GETVIDEOCHAT_DETAILS_FAILURE, error } }
}

function addProcedure(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().appointment.procForm,
			appointment: getState().appointment.appointment.id,
			dentist: getState().appointment.appointment.dentist.id,
			patient: getState().appointment.appointment.patient.id
		}
		procedureService.addProcedure(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: appointmentConstants.ADDPROCEDURE_REQUEST } }
    function success(data) { return { type: appointmentConstants.ADDPROCEDURE_SUCCESS, data } }
    function failure(error) { return { type: appointmentConstants.ADDPROCEDURE_FAILURE, error } }
};

function openChatBox(chatID, name, avatar){
	return dispatch => {
		dispatch(open());
		dispatch(getMessages());
		dispatch(getNewMessages());
	}
	function open() { return {type: appointmentConstants.OPENCHATBOX , data:{chatID:chatID, name:name, avatar:avatar}} }
}

function closeChatBox(chatID){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: appointmentConstants.CLOSECHATBOX } }
}

function gePatientUnassignedProcedures(patientID, apptID){
	return dispatch => {
		dispatch(request());
		procedureService.getPatientUnassignedProcedures(patientID, apptID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: procedureConstants.GETPATIENT_UNASSIGNEDPROCEDURES_REQUEST } }
    function success(data) { return { type: procedureConstants.GETPATIENT_UNASSIGNEDPROCEDURES_SUCCESS, data } }
    function failure(error) { return { type: procedureConstants.GETPATIENT_UNASSIGNEDPROCEDURES_FAILURE, error } }
};

function getInbox(){
	return dispatch => {
		dispatch(request());
		appointmentService.getInbox()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: appointmentConstants.GET_INBOX_REQUEST } }
    function success(data) {
    	// flattening the objects to a list of ids and dictionary of objects
    	// [id1,id2] & {id1: {thread}, id2: {thread} }
    	const inboxObj = data.chat_list.reduce((accumulator, current) => {
    		accumulator[current.thread.id] = current
    		return accumulator
    	}, {});
    	const inboxList = data.chat_list.map((thread, index) => {
    		return thread.thread.id
    	});
    	const result = {chat_list: inboxList, chat_objects: inboxObj}
    	return { type: appointmentConstants.GET_INBOX_SUCCESS, data: result }
    }
    function failure(error) { return { type: appointmentConstants.GET_INBOX_FAILURE, error } }
};


function getNewInbox(){
	return (dispatch, getState) => {
		var tm;
		if (getState().appointment.chatLastRetrieved == null){
			tm = moment(new Date()).format()
		} else {
			tm = moment(getState().appointment.chatLastRetrieved).format()
		}
		dispatch(request());
		appointmentService.getNewInbox(tm)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: appointmentConstants.GET_NEWINBOX_REQUEST } }
    function success(data) {
    	// flattening the objects to a list of ids and dictionary of objects
    	// [id1,id2] & {id1: {thread}, id2: {thread} }
    	const inboxObj = data.chat_list.reduce((accumulator, current) => {
    		accumulator[current.thread.id] = current
    		return accumulator
    	}, {});
    	const inboxList = data.chat_list.map((thread, index) => {
    		return thread.thread.id
    	});
    	const result = {chat_list: inboxList, chat_objects: inboxObj}

    	return { type: appointmentConstants.GET_NEWINBOX_SUCCESS, data:result }
    }
    function failure(error) { return { type: appointmentConstants.GET_NEWINBOX_FAILURE, error } }
};

function sendMessage(message){
	return (dispatch, getState) => {
		dispatch(request());
		const chatID = getState().appointment.activeChatBox
		appointmentService.sendMessage({body: message, thread:chatID})
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: appointmentConstants.SEND_MESSAGE_REQUEST } }
    function success(data) { return { type: appointmentConstants.SEND_MESSAGE_SUCCESS, data } }
    function failure(error) { return { type: appointmentConstants.SEND_MESSAGE_FAILURE, error } }
};

function getMessages(){
	return (dispatch, getState)  => {
		const chatID = getState().appointment.activeChatBox
		dispatch(request());
		appointmentService.getMessages(chatID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};
    function request() { return { type: appointmentConstants.GET_MESSAGES_REQUEST } }
    function success(data) {
    	return { type: appointmentConstants.GET_MESSAGES_SUCCESS, data }
 	}
    function failure(error) { return { type: appointmentConstants.GET_MESSAGES_FAILURE, error } }
};

function getNewMessages(){
	return (dispatch, getState) => {
		var tm;
		if (getState().appointment.activeChatLastRetrieved == null){
			tm = moment(new Date()).format()
		} else {
			tm = moment(getState().appointment.activeChatLastRetrieved).format()
		}
		const chatID = getState().appointment.activeChatBox
		dispatch(request());
		if (chatID == null){
			dispatch(failure("noChatID"));
		}
		appointmentService.getNewMessages(chatID)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	};

    function request() { return { type: appointmentConstants.GET_NEWMESSAGES_REQUEST } }
    function success(data) { return { type: appointmentConstants.GET_NEWMESSAGES_SUCCESS, data } }
    function failure(error) { return { type: appointmentConstants.GET_NEWMESSAGES_FAILURE, error } }
};

function openProcForm(toothNum, surface){
	return dispatch => {
		dispatch(open(toothNum, surface));
	}
	function open(toothNum, surface) { return {type: appointmentConstants.OPENPROCFORM, data:{tooth:toothNum, surface:surface} } }
};

function closeProcForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: appointmentConstants.CLOSEPROCFORM}}
};

function changeProcForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: appointmentConstants.CHANGEPROCFORM, form} }
};
