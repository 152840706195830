export const appointmentConstants = {
	UNMOUNT_APPT: 'APPT_UNMOUNT_APPT',

	SETAPPOINTMENT_ID: 'APPT_SETAPPOINTMENT_ID',
	CHART_SELECT_TOOTH: 'APPT_SELECTTOOTH',
	CHART_SELECT_TOOTH_PROCEDURE: 'APPT_SELECTPROCEDURE',
	CHANGE_PAGECONTENT: 'APPT_CHANGE_PAGECONTENT',

	OPEN_VIDEO_CHAT: 'APPT_OPEN_CHAT',
	END_VIDEO_SESSION: 'APPT_END_VIDEO_SESSION',

	PREV_UPLOADS: 'APPT_PREV_UPLOADS',
	NEXT_UPLOADS: 'APPT_NEXT_UPLOADS',

	OPENPROCFORM:'APPT_OPENPROCFORM', 
	CLOSEPROCFORM:'APPT_CLOSEPROCFORM', 
	CHANGEPROCFORM:'APPT_CHANGEPROCFORM',

	GETAPPOINTMENT_DETAILS_REQUEST : 'APPT_GETAPPOINTMENT_DETAILS_REQUEST',
	GETAPPOINTMENT_DETAILS_SUCCESS : 'APPT_GETAPPOINTMENT_DETAILS_SUCCESS',
	GETAPPOINTMENT_DETAILS_FAILURE : 'APPT_GETAPPOINTMENT_DETAILS_FAILURE',

	GETVIDEOCHAT_TOKEN_REQUEST : 'APPT_GETVIDEOCHAT_TOKEN_REQUEST',
	GETVIDEOCHAT_TOKEN_SUCCESS : 'APPT_GETVIDEOCHAT_TOKEN_SUCCESS',
	GETVIDEOCHAT_TOKEN_FAILURE : 'APPT_GETVIDEOCHAT_TOKEN_FAILURE',


	GETPROCEDURECODES_REQUEST : 'APPT_GETPROCEDURECODES_REQUEST',
	GETPROCEDURECODES_SUCCESS : 'APPT_GETPROCEDURECODES_SUCCESS',
	GETPROCEDURECODES_FAILURE : 'APPT_GETPROCEDURECODES_FAILURE',

	GET_TOOTHREGIONS_REQUEST : 'APPT_GET_TOOTHREGIONS_REQUEST',
	GET_TOOTHREGIONS_SUCCESS : 'APPT_GET_TOOTHREGIONS_SUCCESS',
	GET_TOOTHREGIONS_FAILURE : 'APPT_GET_TOOTHREGIONS_FAILURE',

	EDITAPPOINTMENT_REQUEST : 'APPT_EDITAPPOINTMENT_REQUEST',
	EDITAPPOINTMENT_SUCCESS : 'APPT_EDITAPPOINTMENT_SUCCESS',
	EDITAPPOINTMENT_FAILURE : 'APPT_EDITAPPOINTMENT_FAILURE',

	ADDPROCEDURE_REQUEST : 'APPT_ADDPROCEDURE_REQUEST',
	ADDPROCEDURE_SUCCESS : 'APPT_ADDPROCEDURE_SUCCESS',
	ADDPROCEDURE_FAILURE : 'APPT_ADDPROCEDURE_FAILURE',

	EDITPROCEDURE_REQUEST : 'APPT_EDITPROCEDURE_REQUEST',
	EDITPROCEDURE_SUCCESS : 'APPT_EDITPROCEDURE_SUCCESS',
	EDITPROCEDURE_FAILURE : 'APPT_EDITPROCEDURE_FAILURE',

	DELETEPROCEDURE_REQUEST : 'APPT_DELETEPROCEDURE_REQUEST',
	DELETEPROCEDURE_SUCCESS : 'APPT_DELETEPROCEDURE_SUCCESS',
	DELETEPROCEDURE_FAILURE : 'APPT_DELETEPROCEDURE_FAILURE',

	CHANGEAPPOINTMENT_STATUS_REQUEST : 'APPT_CHANGEAPPOINTMENT_STATUS_REQUEST',
	CHANGEAPPOINTMENT_STATUS_SUCCESS : 'APPT_CHANGEAPPOINTMENT_STATUS_SUCCESS',
	CHANGEAPPOINTMENT_STATUS_FAILURE : 'APPT_CHANGEAPPOINTMENT_STATUS_FAILURE',

	GET_NEWMESSAGES_REQUEST : 'APPT_GET_NEWMESSAGES_REQUEST',
	GET_NEWMESSAGES_SUCCESS : 'APPT_GET_NEWMESSAGES_SUCCESS',
	GET_NEWMESSAGES_FAILURE : 'APPT_GET_NEWMESSAGES_FAILURE',

	GET_MESSAGES_REQUEST : 'APPT_GET_MESSAGES_REQUEST',
	GET_MESSAGES_SUCCESS : 'APPT_GET_MESSAGES_SUCCESS',
	GET_MESSAGES_FAILURE : 'APPT_GET_MESSAGES_FAILURE',

	OPENCHATBOX: 'APPT_OPENCHATBOX',
	CLOSECHATBOX: 'APPT_CLOSECHATBOX',

	SEND_MESSAGE_REQUEST : 'APPT_SEND_MESSAGE_REQUEST',
	SEND_MESSAGE_SUCCESS : 'APPT_SEND_MESSAGE_SUCCESS',
	SEND_MESSAGE_FAILURE : 'APPT_SEND_MESSAGE_FAILURE',

	GET_INBOX_REQUEST : 'APPT_GET_INBOX_REQUEST',
	GET_INBOX_SUCCESS : 'APPT_GET_INBOX_SUCCESS',
	GET_INBOX_FAILURE : 'APPT_GET_INBOX_FAILURE',

	GET_NEWINBOX_REQUEST : 'APPT_GET_NEWINBOX_REQUEST',
	GET_NEWINBOX_SUCCESS : 'APPT_GET_NEWINBOX_SUCCESS',
	GET_NEWINBOX_FAILURE : 'APPT_GET_NEWINBOX_FAILURE'
}