import React from 'react';
import { lazyImport } from '../../_helpers/lazyImport';

import { Switch, Redirect } from 'react-router-dom';

import { PrivateRouteAlt } from '../../_components';

const { AppointmentRequests } = lazyImport(()=> import("./AppointmentRequests"), "AppointmentRequests")
const { Appointments } = lazyImport(()=> import("./Appointments"), "Appointments")

//const { RecipientsList } = lazyImport(()=> import("./Recipients/RecipientsList"), "RecipientsList")
//const { ScheduledMessagesList } =lazyImport(()=> import("./Messages/MessagesList"), "ScheduledMessagesList")

const AppointmentsRoutes = () => {
  return (
    <Switch>
      <PrivateRouteAlt exact path="/appointments/requests"><AppointmentRequests /></PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments/calendar"><Appointments /></PrivateRouteAlt>
      <PrivateRouteAlt exact path="/appointments">
        <Redirect to={{pathname: '/appointments/calendar'}} />
      </PrivateRouteAlt>
    </Switch>
  )
}

export default AppointmentsRoutes;