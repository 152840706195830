import React from 'react';
import { lazyImport } from '../../_helpers/lazyImport';

import { Switch } from 'react-router-dom';

import { PrivateRoute } from '../../_components';

const { AdminPage } = lazyImport(()=> import("../AdminPage"), "AdminPage")
const { AdminDentalPracticePage } = lazyImport(()=> import("../AdminDentalPracticePage"), "AdminDentalPracticePage")
const { AdminCreateNewAccountPage } = lazyImport(()=> import("../AdminCreateNewAccountPage"), "AdminCreateNewAccountPage")
const { AdminDentalAccountPage } = lazyImport(()=> import("../AdminDentalAccountPage"), "AdminDentalAccountPage")
const { AppointmentCalendar } = lazyImport(()=> import("../features/AppointmentCalendar/AppointmentCalendar"), "AppointmentCalendar")

export const AdminPageRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/bd/admin/:page_name(clinics|accounts|calendars)" component={AdminPage} />
      <PrivateRoute exact path="/bd/admin/newAccount" component={AdminCreateNewAccountPage} />
      <PrivateRoute exact path="/bd/admin/clinics/:clinic_id" component={AdminDentalPracticePage} />
      <PrivateRoute exact path="/bd/admin/accounts/:account_id" component={AdminDentalAccountPage} />
      <PrivateRoute exact path="/bd/admin/calendars/:calendarId" component={AppointmentCalendar} />
      <PrivateRoute exact path="/bd/admin" component={AdminPage} />
    </Switch>
  )
}